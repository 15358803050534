import BasicInfo from "./BasicInfo";
import ContractInfo from "./ContractInfo";
import { useFormContext } from "../../context/FormContext";
import { AnimatePresence } from "framer-motion";

const FormPage = ({ closeModal }) => {
  const { page } = useFormContext();
  let contentPage;

  if (page === 1) {
    contentPage = (
      <AnimatePresence mode="wait" initial={false}>
        <BasicInfo closeModal={closeModal} />
      </AnimatePresence>
    );
  } else if (page === 2) {
    contentPage = (
      <AnimatePresence mode="wait">
        <ContractInfo closeModal={closeModal} />;
      </AnimatePresence>
    );
  } else {
    contentPage = <p>new Page</p>;
  }

  return <div>{contentPage}</div>;
};

export default FormPage;
