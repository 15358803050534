import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const scenarioResultsSlice = createSlice({
  name: "scenarioResults",
  initialState: {
    scenarioData: [],
  },
  reducers: {
    addCase: (state, action) => {
      const newScenario = {
        id: `Scenario ${uuidv4()}`,
        timestamp: new Date().toLocaleString(),
        name: action.payload.name,
        description: action.payload.description,
        results: action.payload.results,
      };
      state.scenarioData.push(newScenario);
    },

    removeCase: (state, action) => {
      state.scenarioData = state.scenarioData.filter(
        (scenario) => scenario.id !== action.payload
      );
    },
  },
});

export default scenarioResultsSlice.reducer;
export const { addCase, removeCase } = scenarioResultsSlice.actions;
