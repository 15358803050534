import { Outlet } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import SideNavBar from "../components/SideNav";

const RootLayout = () => {
  return (
    <>
      <MainHeader />
      <div className="root_layout">
        <SideNavBar />
        <div className="root_outlet">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default RootLayout;
