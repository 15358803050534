import classes from "./DataTable.module.css";
import { memo, useMemo } from "react";

const TableRow = memo(({ row, prepareRow }) => {
  prepareRow(row);
  return (
    <tr {...row.getRowProps({ className: classes.datatable_row })}>
      {row.cells.map((cell) => (
        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
      ))}
    </tr>
  );
});

const TableBody = ({ getTableBodyProps, rows, prepareRow }) => {
  const body = useMemo(() => {
    return rows.map((row, index) => (
      <TableRow key={index} row={row} prepareRow={prepareRow}  />
    ));
  }, [rows, prepareRow]);
  return <tbody {...getTableBodyProps()}>{body}</tbody>;
};

export default TableBody;
