import { useSelector } from "react-redux";
import classes from "./BulkEdit.module.css";
import { useModal } from "../../../hooks/useModal";
import ModalWrapper from "../../UI/Modal/ModalWrapper";
import DataTitleButton from "../../UI/Buttons/DataTitleButton";
import BulkEditForm from "./BulkEditForm";
import FormProvider from "../context/FormContext";

const BulkEdit = () => {
  const { data } = useSelector((state) => state.partnerLine);
  const { isModalOpen, handleCloseModal, handleOpenModal } = useModal();
  const isAnySelected = data.some((line) => line.selected);

  if (isAnySelected) {
    return (
      <>
        <DataTitleButton data-title="Edit Selected" onClick={handleOpenModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className={classes.bulk_edit_icon}>
            <path d="M21.7,13.35L20.7,14.35L18.65,12.3L19.65,11.3C19.86,11.08 20.21,11.08 20.42,11.3L21.7,12.58C21.92,12.79 21.92,13.14 21.7,13.35M12,18.94L18.07,12.88L20.12,14.93L14.06,21H12V18.94M4,2H18A2,2 0 0,1 20,4V8.17L16.17,12H12V16.17L10.17,18H4A2,2 0 0,1 2,16V4A2,2 0 0,1 4,2M4,6V10H10V6H4M12,6V10H18V6H12M4,12V16H10V12H4Z" />
          </svg>
        </DataTitleButton>
        <ModalWrapper
          isOpen={isModalOpen}
          wrapperClassName={classes.bulk_edit_modal}
          onClose={handleCloseModal}>
          <FormProvider>
            <BulkEditForm closeModal={handleCloseModal} />
          </FormProvider>
        </ModalWrapper>
      </>
    );
  } else {
    return;
  }
};

export default BulkEdit;
