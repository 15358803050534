import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { Provider } from "react-redux";
import store from "./store/index.js";
import {apiAccessRequest, msalInstance} from "./pages/Auth/index.js";
import {MsalProvider} from "@azure/msal-react";
import {EventType} from "@azure/msal-browser";

msalInstance.initialize().then(() => {
    // Configure active account
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback(event => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload
            const account = payload.account;
            msalInstance.setActiveAccount(account);
        }
    });

    ReactDOM.createRoot(document.getElementById("root")).render(
        <React.StrictMode>
            <MsalProvider instance={msalInstance}>
                <Provider store={store}>
                    <App/>
                </Provider>
            </MsalProvider>
        </React.StrictMode>
    );
});
