import FormHeader from "../FormUI/FormHeader";
import FormFieldDropdown from "../FormUI/FormFieldDropdown";
import { useFormContext } from "../context/FormContext";
import { PrimaryButton, SecondaryButton } from "../../UI/Buttons/CTAButtons";
import classes from "./BulkEdit.module.css";
import { useDispatch, useSelector } from "react-redux";
import { editWithSelectedField } from "../../../store/partnerLine";

const BulkEditForm = ({ closeModal }) => {
  const { inputValues } = useFormContext();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.partnerLine);

  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedItems = data.filter((item) => item.selected === true);
    const partner_name = inputValues["partner_name"];
    const contract_type = inputValues["contract_type"];
    const bundle_code = inputValues["bundle_code"];
    dispatch(
      editWithSelectedField({
        updatedLines: selectedItems,
        partner_name,
        contract_type,
        bundle_code,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormHeader onClose={closeModal} />
      <p className={classes.bulk_edit_title}>Edit fields for selected lines</p>
      <div className={classes.bulk_edit_fields}>
        <FormFieldDropdown name="partner_name" />
        <FormFieldDropdown name="contract_type" />
        <FormFieldDropdown name="bundle_code" />
      </div>
      <div className={classes.bulk_edit_buttons}>
        <SecondaryButton type="button" onClick={closeModal}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" onClick={closeModal}>Edit line/s</PrimaryButton>
      </div>
    </form>
  );
};

export default BulkEditForm;
