import { useState, useRef } from "react";

export const useFormSubmit = (submitFunction) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const abortController = useRef(new AbortController());

  const handleSubmit = async (formData, callback) => {
    setIsSubmitting(true);
    abortController.current = new AbortController();
    try {
      const result = await submitFunction(
        formData,
        abortController.current.signal
      );
      callback(result);
    } catch (error) {
      if (
        error instanceof DOMException &&
        error.message === "The user aborted a request."
      ) {
        console.log("Form submission was cancelled by the user.");
      } else {
        console.error("Error during form submission:", error);
        setError(`${error}  Please try again.`);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    abortController.current.abort();
    setIsSubmitting(false);
  };

  return { isSubmitting, handleSubmit, handleCancel, error };
};
