// componets
import AnimatedPage from "../../../components/UI/AnimatedPage";
import DataTable from "../../../components/DataTable";
import { tableColumns } from "../../../components/Results/ResultsDetails/tableColumns";
import SearchPartnerDetails from "../../../components/Results/ResultsDetails/SearchPartnerDetails";
// redux & router & utils
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { filterArrayByObjectWithPartialMatch } from "../../../utils/filteringLogic";
// hooks
import useFormFields from "../../../hooks/useFormFields";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import { useState, useMemo } from "react";
// styles
import classes from "./ScenarioDetails.module.css";

const ScenarioDetailsPage = () => {
  const { id } = useParams();
  const { inputValues, handleChange, cancelChange } = useFormFields({
    partner_name: "",
    contract_type: "",
  });

  const [debouncedInputValues, setDebouncedInputValues] = useState(inputValues);

  const scenario = useSelector((state) =>
    state.scenarioResults.scenarioData.find((scenario) => scenario.id === id)
  );

  useDebouncedEffect(
    () => {
      setDebouncedInputValues(inputValues);
    },
    500,
    [inputValues]
  );

  const filteredData = useMemo(() => {
    return filterArrayByObjectWithPartialMatch(scenario.results, debouncedInputValues);
  }, [debouncedInputValues, scenario.results]);

  const columnsMemo = useMemo(() => (tableColumns), []) 

  return (
    <AnimatedPage>
      <div className={classes.details_header}>
        <h3>{scenario.name}</h3>
        <SearchPartnerDetails
          inputValues={inputValues}
          handleChange={handleChange}
          cancelChange={cancelChange}
        />
      </div>
      <DataTable
        data={filteredData}
        columns={columnsMemo}
        hideActions={true}
      />
    </AnimatedPage>
  );
};

export default ScenarioDetailsPage;
