import { useSelector } from "react-redux";

const useUniqueDataValues = (columnName) => {
  const { data } = useSelector((state) => state.partnerLine);
  
  // Filter out the specified column values
  let columnValues = data.map(obj => obj[columnName]);

  // Use a Set to get unique values
  let uniqueValues = new Set(columnValues);

  // Convert Set back to an array of objects with value and label properties
  let uniqueArray = Array.from(uniqueValues).map(value => ({ value, label: value }));

  return uniqueArray;
};

export default useUniqueDataValues;
