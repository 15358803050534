import classes from "./FooterDropdown.module.css";
import { useState, useRef } from "react";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { AnimatePresence } from "framer-motion";
import DropdownList from "./DropdownList";

// arrow icon
const icon = (
  <svg
    className={classes.dropdown_button_text_icon}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24">
    <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
  </svg>
);

const FooterDropdown = ({ pageSize, setPageSize }) => {
  const [open, setOpen] = useState(false);
  const dropdown = useRef();
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleSelect = (optionValue) => {
    setPageSize(Number(optionValue));
    handleOpen();
  };
  useOutsideClick(dropdown, () => setOpen(false));

  return (
    <div ref={dropdown} className={classes.dropdown}>
      <span>Items per page: </span>
      <div className={classes.dropdown_wraper}>
        <button onClick={handleOpen} className={classes.dropdown_button}>
          <div className={classes.dropdown_button_text}>
            <div>{pageSize}</div>
            <div>{icon}</div>
          </div>
        </button>
        <AnimatePresence>
          {open && (
            <DropdownList
              className={classes.dropdown_menu}
              pageSize={pageSize}
              handleSelect={handleSelect}
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default FooterDropdown;
