import { useFormContext } from "../../../context/FormContext";
import { rules } from "../../../../../utils/FormValidationRules";
import { motion } from "framer-motion";
import { slideInOut } from "../../../../../utils/animations";
import PageHeader from "../PageHeader";
import ContractFields from "./ContractFields";
import classes from "../FormPages.module.css";
import ArrowDropdown from "../../../../UI/Icons/ArrowDropdown";
import { SecondaryButton, PrimaryButton } from "../../../../UI/Buttons/CTAButtons";

const ContractInfo = ({ closeModal }) => {
  const { changePage, validateField, inputValues } = useFormContext();
  const validationRules = rules(null);

  const handleSubmit = () => {
    validateField(
      "bundle_code",
      inputValues.bundle_code,
      validationRules.bundle_code
    );
    validateField(
      "driven_km",
      inputValues.driven_km,
      validationRules.driven_km
    );
    validateField(
      "driven_net_revenue",
      inputValues.driven_net_revenue,
      validationRules.driven_net_revenue
    );
    validateField(
      "infra_per_km_cost",
      inputValues.infra_per_km_cost,
      validationRules.infra_per_km_cost
    );
    validateField(
      "mg_per_km_cost",
      inputValues.mg_per_km_cost,
      validationRules.mg_per_km_cost
    );
    validateField(
      "partner_share",
      {
        partnerShare: inputValues.partner_share,
        contractType: inputValues.contract_type,
      },
      validationRules.partner_share
    );
  };

  return (
    <motion.div
      key="page-2"
      variants={slideInOut}
      initial="hidden"
      animate="visible"
      exit="exit">
      <PageHeader>Contact info </PageHeader>
      <ContractFields />
      <div className={classes.nav_btns}>
        <SecondaryButton
          type="button"
          onClick={() => changePage(-1)}
          className={classes.nav_cancel_btn}>
          <ArrowDropdown
            style={{ transform: "rotate(180deg)", fill: "white" }}
          />
          <span>Previous Page </span>
        </SecondaryButton>
        <SecondaryButton
          type="button"
          onClick={closeModal}
          className={classes.nav_cancel_btn}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          type="submit"
          onClick={handleSubmit}
          className={classes.nav_btn}>
          Add New Line
        </PrimaryButton>
      </div>
    </motion.div>
  );
};

export default ContractInfo;
