import { useCallback } from "react";
import SearchField from "./SearchField";
import classes from "./SearchForm.module.css";

const SearchForm = ({ inputValues, handleChange, cancelChange }) => {
  return (
    <>
      <form
        onSubmit={(e) => e.preventDefault()}
        className={classes.search_filters}>
        <SearchField
          label="Region"
          name="region_name"
          value={inputValues.region_name}
          onChange={handleChange}
          onDelete={useCallback(
            () => cancelChange("region_name"),
            [cancelChange]
          )}
        />
        <SearchField
          label="Corridor"
          name="corridor_name"
          value={inputValues.corridor_name}
          onChange={handleChange}
          onDelete={useCallback(
            () => cancelChange("corridor_name"),
            [cancelChange]
          )}
        />
        <SearchField
          label="Sub-corridor"
          name="subcorridor_name"
          value={inputValues.subcorridor_name}
          onChange={handleChange}
          onDelete={useCallback(
            () => cancelChange("subcorridor_name"),
            [cancelChange]
          )}
        />
        <SearchField
          label="Partner"
          name="partner_name"
          value={inputValues.partner_name}
          onChange={handleChange}
          onDelete={useCallback(
            () => cancelChange("partner_name"),
            [cancelChange]
          )}
        />
        <SearchField
          label="Bundle"
          name="bundle_code"
          value={inputValues.bundle_code}
          onChange={handleChange}
          onDelete={useCallback(
            () => cancelChange("bundle_code"),
            [cancelChange]
          )}
        />
        <SearchField
          label="Line"
          name="line_name"
          value={inputValues.line_name}
          onChange={handleChange}
          onDelete={useCallback(
            () => cancelChange("line_name"),
            [cancelChange]
          )}
        />
        <SearchField
          label="Contract"
          name="contract_type"
          value={inputValues.contract_type}
          onChange={handleChange}
          onDelete={useCallback(
            () => cancelChange("contract_type"),
            [cancelChange]
          )}
        />
      </form>
    </>
  );
};

export default SearchForm;
