import classes from "./ResultBlock.module.css";
import { Link } from "react-router-dom";
import { sumProperty, divideZero } from "../../utils/mathFunctions";
import { formatCurrency, formatNumberToFixed } from "../../utils/formatData";
import ResultField from "./ResultField";
import { RemoveScenario } from "./ResultsActions";

const ResultBlock = ({ scenario }) => {
  const profit = sumProperty(scenario.results, "GP1");
  const sharableRevenue = sumProperty(
    scenario.results,
    "partner_sharable_revenue"
  );
  const sharableRevenueCurrent = sumProperty(
    scenario.results,
    "real_partner_sharable_revenue"
  );
  const profitCurrent = sumProperty(scenario.results, "real_GP1");
  const profitDifference = profit - profitCurrent;
  const profitFormatted = formatCurrency(profit);
  const profitDifferenceFormatted = formatCurrency(profitDifference);
  const gm1Current = divideZero(profitCurrent, sharableRevenueCurrent);
  const gm1 = divideZero(profit, sharableRevenue);
  const gm1Difference = gm1 - gm1Current;
  const gm1Formatted = formatNumberToFixed(gm1, 1, "%", 100);
  const gm1DifferenceFormatted = formatNumberToFixed(gm1Difference, 1, "%", 100);
  return (
    <div className={classes.result_block}>
      <ResultField label="Scenario Name">{scenario.name}</ResultField>
      <ResultField label="Scenario Description">
        {scenario.description}
      </ResultField>
      <ResultField label="GP1">{profitFormatted}</ResultField>
      <ResultField label="GP1 - difference">
        {profitDifferenceFormatted}
      </ResultField>
      <ResultField label="GM1">{gm1Formatted}</ResultField>
      <ResultField label="GM1 - difference">{gm1DifferenceFormatted}</ResultField>
      <Link to={`/results/${scenario.id}`}>more details</Link>
      <RemoveScenario scenarioId={scenario.id} />
    </div>
  );
};

export default ResultBlock;
