import classes from "./ResultField.module.css";
import InfoWithTooltip from "../UI/Icons/Info";

const ResultField = ({ label, children }) => {
  let content = children;

  if (typeof children === "string" && children.length > 15) {
    content = (
      <>
        <div>
          {children.slice(0, 15)}...
          <InfoWithTooltip
            dataTitle={`${label}: ${children}`}
            role="img"
            aria-label="More information"
          />
        </div>
      </>
    );
  }

  return (
    <section className={classes.result_field}>
      <span>{label}</span>
      <div>{content}</div>
    </section>
  );
};

export default ResultField;
