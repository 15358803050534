import { useFormContext } from "../../../context/FormContext";
import classes from "../FormPages.module.css";
import { motion } from "framer-motion";
import { slideInOut } from "../../../../../utils/animations";
import { rules } from "../../../../../utils/FormValidationRules";
import useUniqueDataValues from "../../../../../hooks/useUniqueDataValues";
import useNavigationOnValidation from "../../../../../hooks/useNavigationOnValidation";
import PageHeader from "../PageHeader";
import BasicFields from "./BasicFields";
import { SecondaryButton, PrimaryButton } from "../../../../UI/Buttons/CTAButtons";

const BasicInfo = ({ closeModal }) => {
  const { changePage, validateField, inputValues, errors } = useFormContext();
  const fields = ["partner_name", "contract_type", "line_name"];
  const { setAttemptingChange } = useNavigationOnValidation(
    errors,
    fields,
    () => changePage(1),
    () => {
      console.log("Invalid - form");
    }
  );

  const uniqueLineNames = useUniqueDataValues("line_name").map(
    (obj) => obj.value
  );

  const validationRules = rules(uniqueLineNames);

  const handleChangePage = () => {
    validateField(
      "partner_name",
      inputValues.partner_name,
      validationRules.partner_name
    );
    validateField(
      "contract_type",
      inputValues.contract_type,
      validationRules.contract_type
    );
    validateField(
      "line_name",
      inputValues.line_name,
      validationRules.line_name
    );
    setAttemptingChange(true);
  };

  return (
    <motion.div
      key="page-1"
      variants={slideInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={classes.motion_div}>
      <PageHeader>Basic Info Page</PageHeader>
      <BasicFields />
      <div className={classes.nav_btns}>
        <SecondaryButton
          className={classes.nav_cancel_btn}
          type="button"
          onClick={closeModal}>
          Cancel
        </SecondaryButton>
        <PrimaryButton type="button" onClick={handleChangePage}>
          <span>Next Page </span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
          </svg>
        </PrimaryButton>
      </div>
    </motion.div>
  );
};

export default BasicInfo;
