import classes from "./ResultBlock.module.css";
import DataTitleButton from "../UI/Buttons/DataTitleButton";
import { removeCase } from "../../store/scenarioResults";
import { useDispatch } from "react-redux";

export const RemoveScenario = ({ scenarioId }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(removeCase(scenarioId));
  };
  return (
    <div className={classes.scenario_delete}>
      <DataTitleButton data-title="Remove scenario" onClick={handleClick}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
        </svg>
      </DataTitleButton>
    </div>
  );
};
