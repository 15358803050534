import ModalWrapper from "../../UI/Modal/ModalWrapper";
import DataTitleButton from "../../UI/Buttons/DataTitleButton";
import AddNewLineForm from "./AddNewLineForm";
import FormProvider from "../context/FormContext";
import { useModal } from "../../../hooks/useModal";
import classes from "./AddNewLine.module.css";

const AddNewLine = () => {
  const { isModalOpen, handleCloseModal, handleOpenModal } = useModal();

  return (
    <>
      <DataTitleButton data-title="Add a new line" onClick={handleOpenModal}>
        <svg
          className={classes.new_line_btn_icon}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24">
          <path d="M17,13H13V17H11V13H7V11H11V7H13V11H17M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" />
        </svg>
      </DataTitleButton>
      <ModalWrapper
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        wrapperClassName={classes.add_new_line_modal}>
        <FormProvider>
          <AddNewLineForm closeModal={handleCloseModal} />
        </FormProvider>
      </ModalWrapper>
    </>
  );
};

export default AddNewLine;
