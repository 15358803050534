import classes from "./TableFooter.module.css";
import AddNewLine from "../../TableActions/AddNewLine";
import SendScenario from "../../TableActions/SendScenario";
import BulkEdit from "../../TableActions/BulkEdit";

const ActionButton = () => {
  return (
    <div className={classes.pagination_action_btn}>
      <BulkEdit />
      <AddNewLine />
      <SendScenario />
    </div>
  );
};

export default ActionButton;
