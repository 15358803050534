import { memo, useState, useCallback } from "react";
import classes from "./FormUI.module.css";
import OnClose from "../../UI/Icons/OnClose";
import { useFormContext } from "../context/FormContext";
import { formatNumberLocale } from "../../../utils/formatData";

const FormFieldContractInput = memo(({ name, label, format }) => {
  const { inputValues, handleChange, cancelChange, errors } = useFormContext();

  const parseValueForEdit = useCallback((value) => {
    const numericString = String(value).replace(/,/g, "");
    return parseFloat(numericString) || 0;
  }, []);

  const [isFocused, setIsFocused] = useState(false);
  const [editValue, setEditValue] = useState(() => inputValues[name] || 0);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
    setEditValue(inputValues[name] ? inputValues[name].toString() : "0");
  }, [inputValues, name]);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    const numericValue = parseValueForEdit(editValue);
    handleChange({
      target: {
        name,
        value: +numericValue,
      },
    });
    setEditValue(format(numericValue));
  }, [handleChange, editValue, name]);

  const onChange = useCallback((e) => {
    setEditValue(e.target.value);
  }, []);

  const onDelete = useCallback(() => {
    cancelChange(name);
    setEditValue("0");
  }, [cancelChange, name]);

  const displayValue = isFocused ? editValue : formatNumberLocale(editValue);

  return (
    <div className={classes.form_box}>
      <div className={classes.form_input_wrapper}>
        <input
          id={`add-new-line-${name}`}
          autoComplete="off"
          className={classes.form_input}
          name={name}
          placeholder=" "
          type="text"
          value={displayValue.toString()}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <label className={classes.form_label} htmlFor={`add-new-line-${name}`}>
          Add {label}
        </label>
        <OnClose dataTitle="Clear" onClick={onDelete} fill={"var(--color)"} />

        <span className={classes.form_error_warning}>{errors[name]}</span>
      </div>
    </div>
  );
});

export default FormFieldContractInput;
