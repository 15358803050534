import classes from "./DataTitleButton.module.css";

const DataTitleButton = ({ children, ...props }) => {
  return (
    <button className={classes.data_title_btn} {...props}>
      {children}
    </button>
  );
};

export default DataTitleButton;
