import {acquireAccessToken} from "../pages/Auth/auth.config.js";

export async function sendScenarioData(data, signal) {
  const apiBaseUrl = import.meta.env.VITE_APP_API_BASE_URL;
  const url = `${apiBaseUrl}/partner-lines-scenario`;

  const authToken = await acquireAccessToken();
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken ? `Bearer ${authToken}` : null
    },
    signal,
  });

  if (!response.ok) {
    const error = new Error("Failed to send scenario data");
    error.status = response.status;
    throw error;
  }

  const resData = await response.json();
  return resData;
}
