import classes from "./DataTable.module.css";
import { useTable, useSortBy, usePagination } from "react-table";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import TableFooter from "./TableFooter";

const DataTable = ({ data, columns, hideActions }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div>
        <table {...getTableProps()} className={classes.datatable}>
          <TableHeader headerGroups={headerGroups} />
          <TableBody
            getTableBodyProps={getTableBodyProps}
            rows={page}
            prepareRow={prepareRow}
          />
        </table>
        <TableFooter
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalRows={rows.length}
          hideActions={hideActions}
        />
      </div>
    </>
  );
};

export default DataTable;
