import useEditableCell from "../../../hooks/useEditableCell";
import CreatableSelect from "react-select/creatable";
import useUniqueDataValues from "../../../hooks/useUniqueDataValues";
import "./EditableDropdown.css";

const EditableDropdown = ({ value, rowId, columnId, optionNames }) => {
  const options = useUniqueDataValues(optionNames);
  const { editValue, setEditValue, saveEdit } = useEditableCell({
    defaultValue: value,
    rowId,
    columnId,
  });

  return (
    <CreatableSelect
      classNamePrefix="react-select"
      menuPlacement="auto"
      maxMenuHeight={175}
      isClearable={false}
      styles={customStyles}
      options={options}
      value={options.find((option) => option.value === editValue)}
      onChange={({ value }) => setEditValue(value)}
      onBlur={saveEdit}
    />
  );
};

export default EditableDropdown;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    outline: "none",
    backgroundColor: "none",
    border: "none",
    borderRadius: "0",
    borderBottom: state.isFocused ? "2px solid var(--green)" : "none",
    boxShadow: "none",
    "&:hover": {
      borderBottom: state.isFocused ? "2px solid var(--green)" : "none",
    },
    transition: "all 0.3s",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "var(--color)" : "var(--color)",
    fontFamily: "var(--font)",
  }),
  input: (provided) => ({
    ...provided,
    color: "var(--color)",
    fontFamily: "var(--font)",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "0.75rem",
    backgroundColor: state.isSelected ? "var(--background)" : "var(--navbar)",
    color: state.isSelected ? "var(--green)" : "var(--color)",
    fontSize: "0.66rem",
    "&:hover": {
      backgroundColor: "var(--background)",
    },
  }),
  menu: (provided) => ({
    ...provided,
    overflowX: "hidden",
    backgroundColor: "var(--navbar)",
    border: "1.5px solid var(--border-color-dark)",
    borderRadius: "calc(var(--border-radius) * 0.5)",
    padding: 0,
    margin: 0,
    zIndex:2,
    alignItems: "center",
  }),
  menuList: (provided) => ({
    ...provided,
    overflowX: "hidden",
    padding: 0,
    margin: 0,
    alignItems: "center",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "var(--green)" : "var(--color)",
    "&:hover": {
      color: "var(--green)",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),

  indicatorContainer: () => ({
    display: "none",
  }),
};
