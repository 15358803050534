import classes from "./FormUI.module.css";
import OnClose from "../../UI/Icons/OnClose";
import { useFormContext } from "../context/FormContext";
import { useCallback } from "react";

const FormFieldInput = ({ name, message }) => {
  const { inputValues, cancelChange, handleChange, errors } = useFormContext();
  const uiMessage = message ? message : "";

  const onDelete = useCallback(() => cancelChange(name), [cancelChange]);

  return (
    <div className={classes.form_box}>
      <div className={classes.form_input_wrapper}>
        <input
          id={name}
          autoComplete="off"
          className={classes.form_input}
          name={name}
          type="text"
          placeholder=" "
          onChange={handleChange}
          value={inputValues[name]}
        />
        <label className={classes.form_label} htmlFor={name}>
          Add {name.split("_")[0]}
        </label>
        <OnClose dataTitle="Clear" onClick={onDelete} fill={"var(--color)"} />
        <span
          className={
            errors[name] ? classes.form_error_warning : classes.form_message
          }>
          {errors[name] ? errors[name] : uiMessage}{" "}
        </span>
      </div>
    </div>
  );
};

export default FormFieldInput;
