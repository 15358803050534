import DataTitleButton from "../../UI/Buttons/DataTitleButton";
import classes from "./FormHeader.module.css";

const FormHeader = ({onClose}) => {
  return (
    <div className={classes.form_header}>
      <DataTitleButton
        onClick={onClose}
        type="button">
        <svg
          className={classes.form_btn_icon}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24">
          <path d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z" />
        </svg>
      </DataTitleButton>
    </div>
  );
};

export default FormHeader;
