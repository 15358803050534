import { fetchPartnerLines } from "../api/loaderPartnerLines";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchPartnerLinesSlice = createAsyncThunk(
  "partnerLines/fetch",
  async () => {
    const response = await fetchPartnerLines();
    return response;
  }
);

const partnerLinesSlice = createSlice({
  name: "partnerLines",
  initialState: {
    data: [],
    status: "idle",
    error: null,
    lastId: 0,
  },
  reducers: {
    // edit cell
    editCell(state, action) {
      const { id, columnName, newValue } = action.payload;
      const selectedLine = state.data.find((line) => line.id === id);

      if (selectedLine) {
        selectedLine[columnName] = newValue;
      }
    },
    // add new row
    addNewLine(state, action) {
      const {
        region_name,
        corridor_name,
        subcorridor_name,
        partner_name,
        bundle_code,
        line_name,
        contract_type,
        driven_km,
        driven_net_revenue,
        mg_per_km_cost,
        infra_per_km_cost,
        partner_share,
      } = action.payload;

      const region = region_name || "No Name Region";
      const corridor = corridor_name || "No Name Corridor";
      const subcorridor = subcorridor_name || "No Name Subcorridor";
      const driven_net_revenue_drived = driven_net_revenue * driven_km;

      const newLine = {
        id: state.lastId + 1,
        region_name: region,
        corridor_name: corridor,
        subcorridor_name: subcorridor,
        partner_name,
        bundle_code,
        line_name,
        contract_type,
        driven_km,
        driven_net_revenue: driven_net_revenue_drived,
        mg_per_km_cost,
        infra_per_km_cost,
        partner_share,
      };
      state.data.push(newLine);
      state.lastId = newLine.id;
    },
    // bulk edit --> multiple lines
    selectBulkEditLines(state, action) {
      const { id, selected } = action.payload;
      const selectedLine = state.data.find((line) => line.id === id);

      if (selectedLine) {
        selectedLine["selected"] = selected;
      }
    },
    // edit with selected
    editWithSelectedField(state, action) {
      const { updatedLines, partner_name, contract_type, bundle_code } =
        action.payload;

      state.data.forEach((line) => {
        const updatedLine = updatedLines.find(
          (uLine) => uLine.id === line.id && uLine.selected
        );

        if (updatedLine && line.selected) {
          line.selected = false;
          line.partner_name = partner_name || line.partner_name;
          line.contract_type = contract_type || line.contract_type;
          line.bundle_code = bundle_code || line.bundle_code;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPartnerLinesSlice.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchPartnerLinesSlice.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        const highestId = action.payload.reduce(
          (maxId, item) => Math.max(maxId, item.id),
          0
        );
        state.lastId = highestId; // set id for adding a new row if need
      })
      .addCase(fetchPartnerLinesSlice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default partnerLinesSlice.reducer;
export const {
  editCell,
  addNewLine,
  selectBulkEditLines,
  editWithSelectedField,
} = partnerLinesSlice.actions;
