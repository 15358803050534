import { useSelector } from "react-redux";
import ResultsList from "../../components/Results/ResultsList";
import AnimatedPage from "../../components/UI/AnimatedPage";

const ResultsPage = () => {
  const { scenarioData } = useSelector((state) => state.scenarioResults);

  let pageHeader = "All scenarios";

  if (scenarioData.length === 0) {
    pageHeader = "No scenarios created";
  }

  return (
    <>
      <AnimatedPage>
        <ResultsList header={pageHeader} scenarios={scenarioData} />
      </AnimatedPage>
    </>
  );
};

export default ResultsPage;
