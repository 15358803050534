import { configureStore } from "@reduxjs/toolkit";

// import reducers
import themeReducer from "./theme";
import partnerLineReducer from "./partnerLine";
import scenarioResultsReducer from "./scenarioResults";

// create store
const store = configureStore({
  reducer: {
    theme: themeReducer,
    partnerLine: partnerLineReducer,
    scenarioResults: scenarioResultsReducer,
  },
});

// export
export default store;
