export const formatCurrency = (value, currency = "EUR") => {
  return value.toLocaleString("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const formatNumberLocale = (value) => {
  if (value === "") {
    return "0";
  }
  return value.toLocaleString();
};

export const formatNumberToFixed = (
  value,
  fixed = 2,
  sign = "",
  multiply = 1
) => {
  const numericValue = Number(value);
  if (isNaN(numericValue)) return "";

  const formattedValue = (numericValue * multiply).toFixed(fixed);
  return `${formattedValue}${sign}`;
};
