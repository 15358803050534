import { formatCurrency, formatNumberToFixed } from "../../../utils/formatData";
import { divideZero } from "./../../../utils/mathFunctions";
import TableCell from "./TableCell";

export const tableColumns = [
  {
    Header: "--",
    accessor: "select",
  },
  {
    Header: "Partner",
    accessor: "partner_name",
    Cell: ({ value }) => <TableCell>{value}</TableCell>,
  },

  {
    Header: "Contract",
    accessor: "contract_type",
    Cell: ({ value }) => <TableCell>{value}</TableCell>,
  },
  {
    Header: "Op. GP1",
    accessor: "GP1",
    Cell: ({ value }) => <TableCell>{formatCurrency(value)}</TableCell>,
    sortType: (rowA, rowB, id, desc) => {
      let a = rowA.values[id];
      let b = rowB.values[id];
      return a > b ? 1 : -1;
    },
  },
  {
    Header: "Op. GP1 (delta)",
    accessor: (row) => row.GP1 - row.real_GP1,
    Cell: ({ value }) => <TableCell>{formatCurrency(value)}</TableCell>,
    sortType: (rowA, rowB, id, desc) => {
      let a = rowA.values[id];
      let b = rowB.values[id];
      return a > b ? 1 : -1;
    },
  },

  {
    Header: "Op. GM1",
    accessor: (row) => divideZero(row.GP1, row.partner_sharable_revenue),
    Cell: ({ value }) => (
      <TableCell>{formatNumberToFixed(value, 0, "%", 100)}</TableCell>
    ),
    sortType: (rowA, rowB, id, desc) => {
      let a = rowA.values[id];
      let b = rowB.values[id];
      return a > b ? 1 : -1;
    },
  },

  {
    Header: "Op. GM1 (delta)",
    accessor: (row) => {
      const gm1 = divideZero(row.GP1, row.partner_sharable_revenue);
      const gm1_current = divideZero(
        row.real_GP1,
        row.real_partner_sharable_revenue
      );
      return gm1 - gm1_current;
    },
    Cell: ({ value }) => (
      <TableCell>{formatNumberToFixed(value, 0, "%", 100)}</TableCell>
    ),
    sortType: (rowA, rowB, id, desc) => {
      let a = rowA.values[id];
      let b = rowB.values[id];
      return a > b ? 1 : -1;
    },
  },
];
