import { useFormContext } from "../../context/FormContext";
import classes from "../FormPages/FormPages.module.css";

const FormHeader = ({ children }) => {
  const { page } = useFormContext();
  return (
    <p className={classes.page_title}>
      {children} <span>(page {page}-2)</span>
    </p>
  );
};

export default FormHeader;
