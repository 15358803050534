import classes from "./TableFooter.module.css";
import FooterDropdown from "./FooterDropdown";
import PageNavigation from "./PageNavigation";
import ActionButton from "./ActionButton";

const TableFooter = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  pageIndex,
  setPageSize,
  pageSize,
  totalRows,
  hideActions
}) => {
  const pageItemsPerPage = (pageIndex + 1) * pageSize;
  const pageItems = pageItemsPerPage < totalRows ? pageItemsPerPage : totalRows;

  return (
    <div className={classes.pagination}>
      {!hideActions ? <ActionButton /> : <p></p>}
      <div className={classes.pagination_wrapper}>
        <FooterDropdown pageSize={pageSize} setPageSize={setPageSize} />
        <div className={classes.pagination_pages_desc}>
          {pageIndex * pageSize + 1} - {pageItems} of {totalRows}
        </div>
        <PageNavigation
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          pageCount={pageCount}
        />
      </div>
    </div>
  );
};

export default TableFooter;
