import SearchField from "../../SearchForm/SearchField";
import classes from "./SearchPartnerDetails.module.css";
import { useCallback } from "react";

const SearchPartnerDetails = ({ inputValues, handleChange, cancelChange }) => {
  return (
    <form 
      id="search-partner-results"
      onSubmit={(e) => e.preventDefault()}
      className={classes.search_filters}>
      <SearchField
        label="Partner"
        name="partner_name"
        value={inputValues.partner_name}
        onChange={handleChange}
        onDelete={useCallback(
          () => cancelChange("partner_name"),
          [cancelChange]
        )}
      />
      <SearchField
        label="Contract"
        name="contract_type"
        value={inputValues.contract_type}
        onChange={handleChange}
        onDelete={useCallback(
          () => cancelChange("contract_type"),
          [cancelChange]
        )}
        X
      />
    </form>
  );
};

export default SearchPartnerDetails;
