import classes from "./SearchForm.module.css";
import OnClose from "../UI/Icons/OnClose";

const SearchField = ({ label, name, value, onChange, onDelete }) => {
  return (
    <div className={classes.search_box}>
      <div className={classes.search_text}>
        <input
          id={name}
          className={classes.search_input}
          name={name}
          placeholder=" "
          autoComplete="off"
          value={value}
          onChange={(e) => onChange(e)}
        />
        <label htmlFor={name} className={classes.label}>
          Search {label}
        </label>
        <OnClose dataTitle="Clear" onClick={onDelete} />
      </div>
    </div>
  );
};

export default SearchField;
