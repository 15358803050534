import classes from "./DataTable.module.css";
import ArrowDropdown from "../UI/Icons/ArrowDropdown";

const TableHeader = ({ headerGroups }) => {
  return (
    <thead className={classes.datatable_head}>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th
              key={column.id}
              {...column.getHeaderProps(
                column.getSortByToggleProps({ title: "" }),
                {
                  className: classes.datatable_th,
                }
              )}>
              {column.render("Header")}

              <span className={classes.sort_btn}>
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <ArrowDropdown className={classes.arrow} />
                  ) : (
                    <ArrowDropdown className={classes.arrow_down} />
                  )
                ) : (""
                )}
              </span>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default TableHeader;
