/**
 * Custom hook for managing the state and actions of editable table cells.
 **/
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { editCell } from "../store/partnerLine";

export default function useEditableCell({
  defaultValue,
  rowId,
  columnId,
  validate,
}) {
  const [editValue, setEditValue] = useState(defaultValue);
  const dispatch = useDispatch();

  useEffect(() => {
    setEditValue(defaultValue);
  }, [defaultValue]);

  const saveEdit = () => {
    const valueAsString = editValue.toString();
    // validate returns an error message or null
    const validationError = validate ? validate(valueAsString.trim()) : null;
    if (validationError) {
      // alert(validationError); // for now
      setEditValue(defaultValue); // revert to the initial value on validation failure
      return;
    }
    if (valueAsString.trim() === "") {
      setEditValue(defaultValue); // revert to defaultValue if empty
      return;
    }
    const columnsToConvert = [
      "driven_km",
      "driven_net_revenue",
      "mg_per_km_cost",
      "infra_per_km_cost",
      "partner_share",
    ];

    let newValue;
    if (columnsToConvert.includes(columnId)) {
      const numericValue = +valueAsString;
      newValue = numericValue;
    } else {
      newValue = valueAsString;
    }
    dispatch(editCell({ id: rowId, columnName: columnId, newValue: newValue }));
  };

  return { editValue, setEditValue, saveEdit };
}
