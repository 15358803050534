import classes from "./ResultBlock.module.css";
import ResultBlock from "./ResultBlock";

const ResultsList = ({ header, scenarios }) => {
  return (
    <div className={classes.scenarios}>
      <h2>{header}</h2>
      <ul className={classes.scenarios_list}>
        {scenarios.map((scenario) => (
          <li key={scenario.id} className={classes.scenarios_item}>
            <ResultBlock scenario={scenario} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ResultsList;
