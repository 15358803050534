import ErorrPageNotFound from "../components/UI/ErorrPageNotFound";
import MainHeader from "../components/MainHeader";
import SideNavBar from "../components/SideNav";

const ErrorPage = () => {
  return (
    <>
      <MainHeader />
      <div className="root_layout">
        <SideNavBar />
        <div className="root_outlet">
          <ErorrPageNotFound />
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
