import OnClose from "../Icons/OnClose";
import classes from "./TextInputField.module.css";
import { useState } from "react";

const TextInputField = ({
  name,
  formName,
  type = "text",
  onClear,
  children,
}) => {
  const [inputValue, setInputValue] = useState("");
  const onDelete = () => {
    setInputValue("");
    if (onClear) {
      onClear();
    }

    return;
  };

  return (
    <div className={classes.input_wrapper}>
      <div className={classes.text_input_wrapper}>
        <input
          className={classes.text_input}
          type={type}
          id={name}
          name={formName}
          autoComplete="off"
          placeholder=" "
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
        />
        <label htmlFor={name} className={classes.input_label}>
          {children}
        </label>
        <OnClose dataTitle="Clear" onClick={onDelete} />
      </div>
    </div>
  );
};

export default TextInputField;
