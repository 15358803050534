import imgLogo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import classes from "./MainHeader.module.css";

const MainHeader = () => {
  return (
    <header className={classes.header}>
      <Link to="/" className={classes.logo}>
        <img src={imgLogo} alt="flixbus logo" />
      </Link>
    </header>
  );
};

export default MainHeader;
