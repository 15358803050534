import { createContext, useContext, useState } from "react";
import useFormFields from "../../../hooks/useFormFields";
import useFormValidation from "../../../hooks/useFormValidation";

const FormContext = createContext();

export const useFormContext = () => useContext(FormContext);

const FormProvider = ({ children }) => {
  const { inputValues, handleChange, cancelChange } =
    useFormFields({
      region_name: "",
      corridor_name: "",
      subcorridor_name: "",
      partner_name: "",
      bundle_code: "",
      line_name: "",
      contract_type: "",
      driven_km: "0",
      driven_net_revenue: "0",
      mg_per_km_cost: "0",
      infra_per_km_cost: "0",
      partner_share: "0",
    });
  const [page, setPage] = useState(1);

  const changePage = (value) => {
    setPage((prevPage) => prevPage + value);
  };

  const { errors, validateField } = useFormValidation();

  return (
    <FormContext.Provider
      value={{
        inputValues,
        handleChange,
        cancelChange,
        page,
        changePage,
        errors,
        validateField,
      }}>
      {children}
    </FormContext.Provider>
  );
};

export default FormProvider;
