import classes from "./SideNavBar.module.css";
import Home from "./Home";
import SwitchTheme from "../SwitchTheme";
import RefreshData from "../TableActions/RefreshData/RefreshData";
import SendScenarioLink from "../TableActions/SendScenario/SendScenarioLink";

const SideNavBar = () => {
  return (
    <aside className={classes.side_navbar}>
      <div className={classes.side_navbar_paths}>
        <RefreshData position="side" />
        <Home />
        <SendScenarioLink />
      </div>
      <div className={classes.theme_icon}>
        <SwitchTheme />
      </div>
    </aside>
  );
};

export default SideNavBar;
