/**
 * Filters an array of objects based on specified filters, supporting partial and case-insensitive matching for string values.
 *
 * @param {Array} array - The array of objects to be filtered.
 * @param {Object} filters - An object where each key represents a field by which to filter the array. The value for each key is the filter criteria. An empty string value acts as a wildcard, allowing any value for that key. For string fields, the filter performs a case-insensitive, partial match. For non-string fields, the match must be exact.
 *
 * @returns {Array} A filtered array of objects that match all of the specified filter criteria. If a filter value is an empty string, it is ignored, allowing any value for that key in the object.
 *
 * @example
 * // Example usage:
 * const dataArray = [
 *   { name: 'Alice', age: 30, location: 'London' },
 *   { name: 'Bob', age: 25, location: 'Paris' },
 *   { name: 'Charlie', age: 35, location: 'Berlin' },
 *   { name: 'Al', age: 30, location: 'London' },
 * ];
 *
 * const filters = {
 *   name: 'Al',
 *   location: 'London',
 * };
 *
 * const filteredArray = filterArrayByObjectWithPartialMatch(dataArray, filters);
 * // filteredArray would include objects for 'Alice' and 'Al', as both partially match 'Al' in name and exactly match 'London' in location.
 */
export const filterArrayByObjectWithPartialMatch = (array, filters) => {
  // Extract only the active filters (where the value is not an empty string)
  const activeFilters = Object.keys(filters).reduce((acc, key) => {
    if (filters[key] !== "") {
      acc[key] = filters[key];
    }
    return acc;
  }, {});

  return array.filter((item) =>
    Object.keys(activeFilters).every((key) =>
      typeof item[key] === "string" && typeof activeFilters[key] === "string"
        ? item[key].toLowerCase().includes(activeFilters[key].toLowerCase())
        : item[key] === activeFilters[key]
    )
  );
};
