import { funFacts } from "../../../utils/funFacts";
import classes from "./Loader.module.css"

const Loader = () => {
  const randomIndex = Math.floor(Math.random() * funFacts.length);
  const randomFact = funFacts[randomIndex];
  return (
    <div className={classes.loader_container}>
      <div className={classes.loader}>
        <div className={classes.loader_border}></div>
        <div className={classes.loading_text}>Loading...</div>
      </div>
      <p className={classes.fun_fact}>{randomFact}</p>
    </div>
  );
};

export default Loader;
