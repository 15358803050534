import FormPage from "./FormPages/FormActivePage";
import { useFormContext } from "../context/FormContext";
import { useDispatch } from "react-redux";
import { addNewLine } from "../../../store/partnerLine";
import FormHeader from "../FormUI/FormHeader";

const AddNewLineForm = ({ closeModal }) => {
  const { inputValues, errors } = useFormContext();
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    const allFieldsValid = Object.values(errors).every((error) => !error);

    if (allFieldsValid) {
      dispatch(addNewLine(inputValues));
      closeModal();
    } else {
      console.log("form not valid"), console.log(errors);
    }
  };

  return (
    <form onSubmit={handleSubmit} id="add-new-line-form">
      <FormHeader onClose={closeModal} />
      <FormPage closeModal={closeModal} />
    </form>
  );
};

export default AddNewLineForm;
