/**
 * Custom hook to validate inputs from form fields .
 **/
import { useState, useEffect } from "react";

const useNavigationOnValidation = (
  errors,
  fieldsToValidate,
  onValid = () => {},
  onInvalid = () => {}
) => {
  const [attemptingChange, setAttemptingChange] = useState(false);

  useEffect(() => {
    const allValid = fieldsToValidate.every((fieldName) => !errors[fieldName]);
    if (attemptingChange) {
      if (allValid) {
        onValid();
      } else {
        onInvalid();
      }
      setAttemptingChange(false);
    }
  }, [attemptingChange, errors, fieldsToValidate, onValid, onInvalid]);

  return { setAttemptingChange };
};

export default useNavigationOnValidation;
