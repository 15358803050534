import DataTitleButton from "../../UI/Buttons/DataTitleButton";
import { useNavigate, useLocation } from "react-router-dom";
import classes from "../../SideNav/SideNavBar.module.css";

const SendScenarioLink = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = () => navigate("/results");

  const iconClasses =
    location.pathname === "/results" ? classes.active_icon : classes.btn_icon;
  return (
    <DataTitleButton
      data-title="Scenario Results"
      data-tooltip-position="side"
      onClick={handleClick}>
      <svg
        className={iconClasses}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path d="M19 3H14.82C14.4 1.84 13.3 1 12 1S9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M12 3C12.55 3 13 3.45 13 4S12.55 5 12 5 11 4.55 11 4 11.45 3 12 3M7 7H17V5H19V19H5V5H7V7M12 17V15H17V17H12M12 11V9H17V11H12M8 12V9H7V8H9V12H8M9.25 14C9.66 14 10 14.34 10 14.75C10 14.95 9.92 15.14 9.79 15.27L8.12 17H10V18H7V17.08L9 15H7V14H9.25" />
      </svg>
    </DataTitleButton>
  );
};

export default SendScenarioLink;
