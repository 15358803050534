import { motion } from "framer-motion";
import classes from "./FooterDropdown.module.css";

const variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const pageOptions = [5, 10, 20, 50, 100];

const DropdownList = ({ className, pageSize, handleSelect }) => {
  return (
    <motion.ul
      className={className}
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={variants}
      transition={{ duration: 0.3 }}>
      {pageOptions.map((optionValue) => (
        <li key={optionValue} className={classes.dropdown_menu_item}>
          <button
            className={
              optionValue === pageSize
                ? classes.dropdown_menu_button_active
                : ""
            }
            onClick={() => handleSelect(Number(optionValue))}>
            {optionValue}
          </button>
        </li>
      ))}
    </motion.ul>
  );
};

export default DropdownList;
