export const sumProperty = (array, propertyName) => {
  return array.reduce((total, item) => total + item[propertyName], 0);
};

export const divideZero = (numerator, denominator) => {
  if (denominator === 0 || isNaN(denominator)) {
    return 0;
  } else {
    return numerator / denominator;
  }
};
