import FormFieldDropdown from "../../../FormUI/FormFieldDropdown";
import FormFieldContractInput from "../../../FormUI/FormFieldContractInput";
import FormFields from "../../../FormUI/FormFields";

import {
  formatNumberLocale,
  formatNumberToFixed,
} from "../../../../../utils/formatData";

const ContractFields = () => {
  return (
    <FormFields>
      <FormFieldDropdown name="bundle_code" />
      <FormFieldContractInput
        name="driven_km"
        label="driven km"
        format={formatNumberLocale}
      />
      <FormFieldContractInput
        name="driven_net_revenue"
        label="driven net revenue per km"
        format={(value) => formatNumberToFixed(value, 2)}
      />
      <FormFieldContractInput
        name="infra_per_km_cost"
        label="infrastructure costs per km"
        format={(value) => formatNumberToFixed(value, 2)}
      />
      <FormFieldContractInput
        name="mg_per_km_cost"
        label="min. guarantee/sub costs per km"
        format={(value) => formatNumberToFixed(value, 2)}
      />
      <FormFieldContractInput
        name="partner_share"
        label="partner share"
        format={(value) => formatNumberToFixed(value, 0, "%", 100)}
      />
    </FormFields>
  );
};

export default ContractFields;
