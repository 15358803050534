import { defer } from "react-router-dom";
import {acquireAccessToken} from "../pages/Auth/auth.config.js";

export async function fetchPartnerLines() {

  const apiBaseUrl = import.meta.env.VITE_APP_API_BASE_URL;
  const url = `${apiBaseUrl}/partner-lines`;
  const authToken = await acquireAccessToken();
  const options = {
    headers: {
      Authorization: authToken ? `Bearer ${authToken}` : null
    }
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    const error = new Error("An error occurred while fetching the lines");
    error.code = response.status;
    error.info = await response.json();
    throw error;
  }

  const resData = await response.json();
  return resData;
}

export function loader() {
  return defer({
    partnerLines: fetchPartnerLines(),
  });
}
