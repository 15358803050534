import {PublicClientApplication} from '@azure/msal-browser';


const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_APP_AZURE_APPLICATION_ID ?? '',
    authority: import.meta.env.VITE_APP_AZURE_AUTHORITY_URL,
    redirectUri: document.location.origin,
    postLogoutRedirectUri: document.location.origin
  },
  cache: {
    storeAuthStateInCookie: false,
    cacheLocation: 'localStorage'
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const apiAccessRequest = {
  scopes: import.meta.env.VITE_APP_AZURE_ACCESS_TOKEN_CLAIMS_API?.split(',') || []
};

export const acquireAccessToken = async () => {
  // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside the context of MsalProvider
     */

    // Note that this error could occur after clearing msal cache when user interaction is required
    try {
      await msalInstance.handleRedirectPromise();
      if (msalInstance.getAllAccounts().length === 0) {
        await msalInstance.loginRedirect(apiAccessRequest);
      }
    } catch (error) {
      await msalInstance.loginRedirect(apiAccessRequest);
    }
  }

  const accessTokenRequest = {
    scopes: apiAccessRequest.scopes,
    account: activeAccount || accounts[0],
    redirectUri: window.location.origin + '/blank.html'
  };

  try {
    // Acquire token silently to be used in API calls, if an error occurs, redirect to login page
    const token = await msalInstance.acquireTokenSilent(accessTokenRequest);
    return token.accessToken;
  } catch (error) {
    console.error(
        'Error acquiring access token silently, redirecting to login page',
        error
    );
    // Clear msal cache and redirect to login page
    // Note that acquireTokenRedirect results in interaction_in_progress error due to concurrent graphql requests, refer BDTS-1066 for more info
    await msalInstance.clearCache(); // Clear cache should also redirect user to login page
    await msalInstance.loginRedirect(apiAccessRequest);
  }
};
