import { Link } from "react-router-dom";
import classes from "./ErorrPageNotFound.module.css";
import { PrimaryButton } from "./Buttons/CTAButtons";

const ErorrPageNotFound = () => {
  return (
    <div className={classes.error}>
      <h1 className={classes.error_header}>404 Not Found</h1>
      <p className={classes.error_title}>
        Oops! The page you are looking for does not exist.
      </p>
      <PrimaryButton>
        <Link className={classes.error_link} href="/">
          Go back to Home Page
        </Link>
      </PrimaryButton>
    </div>
  );
};

export default ErorrPageNotFound;
