import classes from "./CTAButtons.module.css"

export const SecondaryButton = ({ children, ...props }) => {
  return (
    <button {...props} className={classes.secondary_btn}>
      {children}
    </button>
  );
};

export const PrimaryButton = ({ children, ...props }) => {
  return (
    <button {...props} className={classes.primary_btn}>
      {children}
    </button>
  );
};