import { createSlice } from "@reduxjs/toolkit";

// create init state
const initThemeState = {
  value: "dark",
};

// create slice
const themeSlice = createSlice({
  name: "theme",
  initialState: initThemeState,
  reducers: {
    switchTheme: (state) => {
      state.value = state.value === "dark" ? "light" : "dark";
    },
  },
});

// exports
export default themeSlice.reducer;
export const { switchTheme } = themeSlice.actions;
