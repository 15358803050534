/**
 * Custom hook achive DebouncedEffect.
 **/
import { useEffect, useRef } from "react";

const useDebouncedEffect = (effect, delay, deps) => {
  const lastChange = useRef();

  useEffect(() => {
    if (lastChange.current) {
      clearTimeout(lastChange.current);
    }
    lastChange.current = setTimeout(() => {
      effect();
    }, delay);

    return () => {
      if (lastChange.current) {
        clearTimeout(lastChange.current);
      }
    };
  }, deps);
};

export default useDebouncedEffect;
