import { useEffect, forwardRef } from "react";
import classes from "./Modal.module.css";
import { motion } from "framer-motion";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { slideInOut } from "../../../utils/animations";

const Modal = forwardRef(({ children, onClose, open }, ref) => {
  useOutsideClick(ref, () => {
    if (open) {
      onClose();
    }
  });

  useEffect(() => {
    const modal = ref.current;
    if (open && modal) {
      modal.showModal();
    } else {
      modal?.close();
    }
  }, [open]);

  return (
    <motion.dialog
      key="modal"
      ref={ref}
      className={classes.modal}
      onClose={onClose}
      onClick={(event) => event.stopPropagation()}
      variants={slideInOut}
      initial="hidden"
      animate="visible"
      exit="exit">
      {children}
    </motion.dialog>
  );
});

export default Modal;
