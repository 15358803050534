import classes from "./Cell.module.css";
import { useDispatch } from "react-redux";
import { selectBulkEditLines } from "../../../store/partnerLine";

const CheckBoxCell = ({ row }) => {
  const dispatch = useDispatch();
  const handleChange = (e) => {
    dispatch(selectBulkEditLines({ id: row.id, selected: e.target.checked }));
  };
  return (
    <label className={classes.checkbox_cell}>
      <input
        type="checkbox"
        checked={row.selected || false}
        onChange={handleChange}
      />
      <span className={classes.checkmark_cell}></span>
    </label>
  );
};

export default CheckBoxCell;
