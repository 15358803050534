import { useRef } from "react";
import { createPortal } from "react-dom";
import { AnimatePresence } from "framer-motion";
import Modal from "./Modal";

const ModalWrapper = ({ isOpen, onClose, wrapperClassName, children }) => {
  const dialogRef = useRef(null);

  return createPortal(
    <AnimatePresence mode="wait">
      {isOpen && (
        <div className={wrapperClassName}>
          <Modal ref={dialogRef} open={isOpen} onClose={onClose}>
            {children}
          </Modal>
        </div>
      )}
    </AnimatePresence>,
    document.getElementById("modal")
  );
};

export default ModalWrapper;
