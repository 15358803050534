import classes from "./Icons.module.css";

const InfoWithTooltip = ({ dataTitle }) => {
  return (
    <div className={classes.icon_wrapper} data-title={dataTitle}>
      <svg
        className={classes.simple_icon}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
      </svg>
    </div>
  );
};

export default InfoWithTooltip;
