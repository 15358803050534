import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RootLayout from "./pages/Root";
import HomePage from "./pages/Home";
import ResultsPage from "./pages/ScenarioResults";
import ScenarioDetailsPage from "./pages/ScenarioResults/ScenarioDetails";
import ErrorPage from "./pages/Error";
import { useDispatch } from "react-redux";
import { fetchPartnerLinesSlice } from "./store/partnerLine";
import { useEffect } from "react";
import {AuthenticatedTemplate} from "@azure/msal-react";

// router
const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: "results",
        element: <ResultsPage />,
      },
      {
        path: "results/:id",
        element: <ScenarioDetailsPage />,
      },
    ],
  },
]);


const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPartnerLinesSlice());
  }, [dispatch]);
  return (
    <>

        <AuthenticatedTemplate>
      <RouterProvider router={router} />
        </AuthenticatedTemplate>
    </>
  );
}

export default App;

