export const validateIsNumber = (value) => {
  const numberValue = Number(value);

  if (isNaN(numberValue)) {
    return "The input must be a number.";
  } else if (numberValue < 0) {
    return "The value cannot be less than 0.";
  }

  return null;
};
