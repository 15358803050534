import FormFieldDropdown from "../../../FormUI/FormFieldDropdown";
import FormFieldInput from "../../../FormUI/FormFieldInput";
import FormFields from "../../../FormUI/FormFields";

const BasicFields = () => {
  return (
    <FormFields>
      <FormFieldDropdown name="region_name" message="Not required" />
      <FormFieldDropdown name="corridor_name" message="Not required" />
      <FormFieldDropdown name="subcorridor_name" message="Not required" />
      <FormFieldDropdown name="partner_name" />
      <FormFieldDropdown name="contract_type" />
      <FormFieldInput name="line_name" />
    </FormFields>
  );
};

export default BasicFields;
