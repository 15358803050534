/**
 * Custom hook to get inputs from form fields .
 **/
import { useState, useCallback } from "react";

const useFormFields = (initialValues) => {
  const [inputValues, setInputValues] = useState(initialValues);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const cancelChange = useCallback((name) => {
    setInputValues((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  }, []);

  return { inputValues, handleChange, cancelChange };
};

export default useFormFields;
