// styles
import classes from "./SendScenario.module.css";
// components
import FormHeader from "../FormUI/FormHeader";
import DataTitleButton from "../../UI/Buttons/DataTitleButton";
import TextInputField from "../../UI/Input/TextInputField";
import { SecondaryButton, PrimaryButton } from "../../UI/Buttons/CTAButtons";
// redux & router & react
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
// store & api & hooks
import { sendScenarioData } from "../../../api/sendScenarioData";
import { fetchPartnerLinesSlice } from "../../../store/partnerLine";
import { addCase } from "../../../store/scenarioResults";
import { useFormSubmit } from "../../../hooks/useFormSubmit";

const ScenarioForm = ({ closeModal }) => {
  const { data } = useSelector((state) => state.partnerLine);
  const textRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSuccess = (scenarioResults) => {
    dispatch(addCase(scenarioResults));
    dispatch(fetchPartnerLinesSlice());
    navigate("/results");
  };

  const { isSubmitting, handleSubmit, handleCancel, error } = useFormSubmit(
    async (formData, signal) => {
      const scenarioResults = await sendScenarioData(data, signal);
      return {
        results: scenarioResults,
        name:
          formData.get("ScenarioName") ||
          `Scenario ${new Date().toLocaleString()}`,
        description: formData.get("scenarioDescription"),
      };
    }
  );

  const handleRefClear = () => {
    if (textRef.current) {
      textRef.current.value = "";
    }
  };

  const headerClose = () => {
    handleCancel();
    closeModal();
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const fd = new FormData(e.target);
        handleSubmit(fd, onSuccess);
      }}>
      <FormHeader onClose={headerClose} />
      <div className={classes.scenario_name_and_desc}>
        <TextInputField
          id="scenario-name"
          formName="ScenarioName"
          onClear={handleRefClear}>
          Add scenario name
        </TextInputField>
        <textarea
          ref={textRef}
          className={classes.scenario_desctiption}
          name="scenarioDescription"
          id="scenario-description"
          placeholder="Write some notes if you like..."
          maxLength="500"
          cols="30"
          rows="10"></textarea>
        <div className={classes.send_scenario_nav_buttons}>
          <SecondaryButton
            type="button"
            onClick={() => {
              handleCancel();
              closeModal();
            }}>
            Cancel
          </SecondaryButton>
          <PrimaryButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? <div className={classes.spinner}></div> : ""} Submit
          </PrimaryButton>
        </div>
        {error && <p className={classes.error}>{error}</p>}
      </div>
    </form>
  );
};

export default ScenarioForm;
