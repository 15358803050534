import { memo, useCallback, useState } from "react";
import useEditableCell from "../../../hooks/useEditableCell";
import classes from "./Cell.module.css";

const EditableTextInput = memo(({ value, rowId, columnId, format, validate }) => {
  const parseValueForEdit = (value) => {
    return parseFloat(value.replace(/[^\d.-]/g, ""));
  };

  const [isFocused, setIsFocused] = useState(false);
  const { editValue, setEditValue, saveEdit } = useEditableCell({
    defaultValue: parseValueForEdit(value),
    rowId,
    columnId,
    validate,
  });

  const displayValue = isFocused ? editValue : format ? format(editValue) : editValue;

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    saveEdit(editValue);
  }, [saveEdit, editValue]);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
    setEditValue(parseValueForEdit(value));
  }, [value]);

  return (
    <input
      id={`${rowId}-${columnId}`}
      className={classes.edit_field_text}
      type="text"
      value={displayValue.toString()}
      onChange={useCallback(
        (e) => setEditValue(e.target.value),
        [setEditValue]
      )}
      onFocus={handleFocus}
      onBlur={handleBlur}
      autoComplete="off"
    />
  );
});

export default EditableTextInput;
