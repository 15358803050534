import EditableTextInput from "./Cell/EditableTextInput";
import EditableDropdown from "./Cell/EditableDropdown";
import CheckBoxCell from "./Cell/CheckBoxCell";
import {
  formatCurrency,
  formatNumberLocale,
  formatNumberToFixed,
} from "../../utils/formatData";
import { validateIsNumber } from "../../utils/validation";

export const columns = [
  {
    Header: "-",
    accessor: "select",
    Cell: ({ row: { original } }) => <CheckBoxCell row={original} />,
  },
  {
    Header: "Partner",
    accessor: "partner_name",
    Cell: ({ cell: { value }, row: { original }, column: { id } }) => (
      <EditableDropdown
        value={value}
        rowId={original.id}
        columnId={id}
        optionNames="partner_name"
      />
    ),
  },
  {
    Header: "Bundle",
    accessor: "bundle_code",
    Cell: ({ cell: { value }, row: { original }, column: { id } }) => (
      <EditableDropdown
        value={value}
        rowId={original.id}
        columnId={id}
        optionNames="bundle_code"
      />
    ),
  },
  {
    Header: "Line",
    accessor: "line_name",
    Cell: ({ cell: { value }, row: { original }, column: { id } }) => (
      <EditableDropdown
        value={value}
        rowId={original.id}
        columnId={id}
        optionNames="line_name"
      />
    ),
  },
  {
    Header: "Contract",
    accessor: "contract_type",
    Cell: ({ cell: { value }, row: { original }, column: { id } }) => (
      <EditableDropdown
        value={value}
        rowId={original.id}
        columnId={id}
        optionNames="contract_type"
      />
    ),
  },
  {
    Header: "Distance",
    accessor: "driven_km",
    Cell: ({ value, row: { original }, column: { id } }) => {
      const formattedValue = formatNumberLocale(value);

      return (
        <EditableTextInput
          value={formattedValue}
          rowId={original.id}
          columnId={id}
          format={formatNumberLocale}
          validate={validateIsNumber}
        />
      );
    },
  },
  {
    Header: "Net Revenue",
    accessor: "driven_net_revenue",
    Cell: ({ value, row: { original }, column: { id } }) => {
      const formattedValue = formatCurrency(value);
      return (
        <EditableTextInput
          value={formattedValue}
          rowId={original.id}
          columnId={id}
          format={formatCurrency}
          validate={validateIsNumber}
        />
      );
    },
  },

  {
    Header: "Infra €/km",
    accessor: "infra_per_km_cost",
    Cell: ({ value, row: { original }, column: { id } }) => {
      const formattedValue = formatNumberToFixed(value);
      return (
        <EditableTextInput
          value={formattedValue}
          rowId={original.id}
          columnId={id}
          format={(value) => formatNumberToFixed(value, 2)}
          validate={validateIsNumber}
        />
      );
    },
  },
  {
    Header: "Mg €/km",
    accessor: "mg_per_km_cost",
    Cell: ({ value, row: { original }, column: { id } }) => {
      const formattedValue = formatNumberToFixed(value);
      return (
        <EditableTextInput
          value={formattedValue}
          rowId={original.id}
          columnId={id}
          format={(value) => formatNumberToFixed(value, 2)}
          validate={validateIsNumber}
        />
      );
    },
  },
  {
    Header: "Partner share",
    accessor: "partner_share",
    Cell: ({ value, row: { original }, column: { id } }) => {
      const formattedValue = formatNumberToFixed(value);
      return (
        <EditableTextInput
          value={formattedValue}
          rowId={original.id}
          columnId={id}
          format={(value) => formatNumberToFixed(value, 0, "%", 100)}
          validate={validateIsNumber}
        />
      );
    },
  },
];
