import CreatableSelect from "react-select/creatable";
import useUniqueDataValues from "../../../hooks/useUniqueDataValues";
import { useFormContext } from "../context/FormContext";
import classes from "./FormUI.module.css";
import { useState, useEffect } from "react";

const FormFieldDropdown = ({ name, message }) => {
  const { inputValues, handleChange, errors } = useFormContext();
  const initialOptions = useUniqueDataValues(name);
  const [options, setOptions] = useState(initialOptions);
  const [isSelectFocused, setIsSelectFocused] = useState(false);
  const passedValue = inputValues[name];
  const uiMessage = message ? message : "";

  const localStorageKey = `AddNewLineFormData_${name}`;

  useEffect(() => {
    const savedOptions =
      JSON.parse(sessionStorage.getItem(localStorageKey)) || [];

    const combinedOptions = [
      ...savedOptions,
      ...initialOptions.filter(
        (o) => !savedOptions.find((so) => so.value === o.value)
      ),
    ];

    setOptions(combinedOptions);
  }, [name, localStorageKey]);

  const selectedOption = options.find(
    (option) => option.value.toLowerCase() === passedValue.toLowerCase()
  );

  const handleDropdownChange = (selectedOption) => {
    handleChange({
      target: {
        name: name,
        value: selectedOption ? selectedOption.value : "",
      },
    });
  };

  const handleCreateOption = (inputValue) => {
    setOptions((prevOptions) => {
      const optionExists = prevOptions.some(
        (option) => option.value === inputValue
      );

      if (!optionExists) {
        const newOption = { value: inputValue, label: inputValue };
        const updatedOptions = [...prevOptions, newOption];

        sessionStorage.setItem(localStorageKey, JSON.stringify(updatedOptions));

        handleChange({
          target: {
            name: name,
            value: inputValue,
          },
        });

        return updatedOptions;
      }

      return prevOptions;
    });
  };

  const handleFocus = () => setIsSelectFocused(true);
  const handleBlur = () => setIsSelectFocused(false);

  const labelClass = `${classes.form_label} ${
    isSelectFocused || selectedOption ? classes.labelShrink : ''
  }`;

  return (
    <div className={classes.form_box}>
      <div className={classes.form_input_wrapper}>
        <CreatableSelect
          name={name}
          classNamePrefix="react-select"
          placeholder={`Select ${name}`}
          menuPlacement="bottom"
          maxMenuHeight="7rem"
          isClearable={false}
          styles={customStyles}
          options={options}
          value={selectedOption || ""}
          closeMenuOnSelect={true}
          onChange={handleDropdownChange}
          onCreateOption={handleCreateOption}
          onFocus={handleFocus}
        onBlur={handleBlur}
        />
        <label className={labelClass} htmlFor={name}>
          Add {name.split("_")[0]}
        </label>
        <span
          className={
            errors[name] ? classes.form_error_warning : classes.form_message
          }>
          {errors[name] ? errors[name] : uiMessage}{" "}
        </span>
      </div>
    </div>
  );
};

export default FormFieldDropdown;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    padding: "0",
    margin: "0",
    width: "16rem",
    height: "2.3rem",
    minHeight: "2.3rem",
    outline: "none",
    overflowX: "hidden",
    backgroundColor: "none",
    border: "none",
    borderRadius: "0",
    fontSize: "0.75rem",
    borderBottom: state.isFocused
      ? "1px solid var(--green)"
      : "1px solid var(--border-color)",
    boxShadow: "none",
    "&:hover": {
      borderBottom: state.isFocused
        ? "1px solid var(--green)"
        : "1px solid var(--border-color)",
    },
    transition: "all 0.3s",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "var(--color)",
    fontFamily: "var(--font)",
  }),
  input: (provided) => ({
    ...provided,
    color: "var(--color)",
    fontFamily: "var(--font)",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "0.75rem",
    backgroundColor: state.isSelected ? "var(--background)" : "var(--navbar)",
    color: state.isSelected ? "var(--green)" : "var(--color)",
    fontSize: "0.75rem",
    "&:hover": {
      backgroundColor: "var(--background)",
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: "16rem",
    overflowX: "hidden",
    backgroundColor: "var(--navbar)",
    border: "1.5px solid var(--border-color-dark)",
    borderRadius: "calc(var(--border-radius) * 0.5)",
    padding: 0,
    margin: 0,
    alignItems: "center",
  }),
  menuList: (provided) => ({
    ...provided,
    overflowX: "hidden",
    padding: 0,
    margin: 0,
    alignItems: "center",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    paddingRight: "0",
    color: state.isFocused ? "var(--green)" : "var(--color)",
    "&:hover": {
      color: "var(--green)",
    },
  }),
  placeholder: () => ({
    display: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),

  indicatorContainer: () => ({
    display: "none",
  }),
};
