/**
 * Custom hook to validate inputs from form fields .
 **/

import { useState, useCallback } from "react";

const useFormValidation = () => {
  const [errors, setErrors] = useState({});

  const validateField = useCallback((name, value, rule) => {
    setErrors((prevErrors) => {
      const error = rule(value);
      return {
        ...prevErrors,
        [name]: error,
      };
    });
  }, []);

  return { errors, validateField };
};

export default useFormValidation;
