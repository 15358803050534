import { useNavigate, useLocation } from "react-router-dom";
import DataTitleButton from "../UI/Buttons/DataTitleButton";
import classes from "./SideNavBar.module.css";
const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const iconClasses =
    location.pathname === "/" ? classes.active_icon : classes.btn_icon;

  const handleClick = () => navigate("/");
  return (
    <DataTitleButton
      data-title="Home / Scenario Creation"
      onClick={handleClick}
      data-tooltip-position="side">
      <svg
        className={iconClasses}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24">
        <path d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" />
      </svg>
    </DataTitleButton>
  );
};

export default Home;
