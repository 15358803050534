import DataTitleButton from "../../UI/Buttons/DataTitleButton";
import ArrowDropdown from "../../UI/Icons/ArrowDropdown";
const PageNavigation = ({
  gotoPage,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  pageCount,
}) => {
  return (
    <div>
      <DataTitleButton
        data-title="First page"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M6,18V6H8V18H6M9.5,12L18,6V18L9.5,12Z" />
        </svg>
      </DataTitleButton>
      <DataTitleButton
        data-title="Previous page"
        onClick={() => previousPage()}
        disabled={!canPreviousPage}>
        <ArrowDropdown style={{ transform: "rotate(180deg)" }} />
      </DataTitleButton>
      <DataTitleButton
        data-title="Next page"
        onClick={() => nextPage()}
        disabled={!canNextPage}>
        <ArrowDropdown />
      </DataTitleButton>
      <DataTitleButton
        data-title="Last page"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M16,18H18V6H16M6,18L14.5,12L6,6V18Z" />
        </svg>
      </DataTitleButton>
    </div>
  );
};

export default PageNavigation;
