export const rules = (uniqueNames) => ({
  partner_name: (value) =>
    !value || value === "" ? "Partner is required" : null,

  contract_type: (value) =>
    !value || value === "" ? "Contract is required" : null,

  line_name: (value) => {
    if (!value || value === "") {
      return "Line is required";
    } else if (uniqueNames.includes(value)) {
      return "Line already exists";
    } else if (value.length < 3) {
      return "Line needs to have min. 3 characters";
    } else {
      return null;
    }
  },

  bundle_code: (value) =>
    !value || value === "" ? "Bundle is required" : null,

  driven_km: (value) => {
    if (value === undefined || value === "") {
      return "Driven km is required";
    } else if (isNaN(value)) {
      return "Not valid number";
    } else if (Number(value) <= 0) {
      return "Value must higher than 0";
    } else {
      return null;
    }
  },
  driven_net_revenue: (value) => {
    if (value === undefined || value === "") {
      return "Driven net revenue is required";
    } else if (isNaN(value)) {
      return "Not valid number";
    } else if (Number(value) <= 0) {
      return "Value must higher than 0";
    } else {
      return null;
    }
  },
  infra_per_km_cost: (value) => {
    if (value === undefined || value === "") {
      return "Infrastructure costs per km is required";
    } else if (isNaN(value)) {
      return "Not valid number";
    } else if (Number(value) < 0) {
      return "Value cannot be lower than 0";
    } else {
      return null;
    }
  },
  mg_per_km_cost: (value) => {
    if (value === undefined || value === "") {
      return "min. guarantee/sub costs per km is required";
    } else if (isNaN(value)) {
      return "Not valid number";
    } else if (Number(value) < 0) {
      return "Value cannot be lower than 0";
    } else {
      return null;
    }
  },
  partner_share: (value) => {
    const { partnerShare, contractType } = value;
    if (partnerShare === undefined || partnerShare === "") {
      return "Partner Share km is required";
    } else if (isNaN(partnerShare)) {
      return "Not valid number";
    } else if (Number(partnerShare) < 0) {
      return "Value cannot be lower than 0";
    } else if (contractType === "Subcontractor" && Number(partnerShare) > 0) {
      return "Subcontractor partner share must be 0%";
    } else if (
      contractType !== "Subcontractor" &&
      contractType !== "Own Operations" &&
      Number(partnerShare) <= 0
    ) {
      return `Partner share for ${contractType} must higher than 0%`;
    } else {
      return null;
    }
  },
});
