import classes from "./Home.module.css";
import SearchForm from "../../components/SearchForm";
import DataTable from "../../components/DataTable";
import useFormFields from "../../hooks/useFormFields";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { filterArrayByObjectWithPartialMatch } from "../../utils/filteringLogic";
import Loader from "../../components/UI/Loader/Loader";
import AnimatedPage from "../../components/UI/AnimatedPage";
import { columns } from "../../components/DataTable/columns";

const HomePage = () => {
  const { inputValues, handleChange, cancelChange } = useFormFields({
    region_name: "",
    corridor_name: "",
    subcorridor_name: "",
    partner_name: "",
    bundle_code: "",
    line_name: "",
    contract_type: "",
  });
  const [debouncedInputValues, setDebouncedInputValues] = useState(inputValues);

  const { data, status, error } = useSelector((state) => state.partnerLine);

  useDebouncedEffect(
    () => {
      setDebouncedInputValues(inputValues);
    },
    500,
    [inputValues]
  );

  const filteredData = useMemo(() => {
    return filterArrayByObjectWithPartialMatch(data, debouncedInputValues);
  }, [debouncedInputValues, data]);

  const columnsMemo = useMemo(() => columns, []);

  // console.log(filteredData)
  let tableContent;
  if (status === "loading") {
    tableContent = <Loader />;
  } else if (status === "succeeded") {
    tableContent = <DataTable data={filteredData} columns={columnsMemo} />;
  } else {
    tableContent = <p>{error}</p>;
  }

  return (
    <AnimatedPage>
      <main className={classes.main_block}>
        <SearchForm
          inputValues={inputValues}
          handleChange={handleChange}
          cancelChange={cancelChange}
        />
        {tableContent}
      </main>
    </AnimatedPage>
  );
};

export default HomePage;
